.button,
button{
    padding: .8rem 1.2rem;
    font-size: 1.4rem;
    font-weight: bold;
    background: $neutral-50;
    border: .1rem solid $neutral-200;
    border-radius: .8rem;
    text-transform: uppercase;
    cursor: pointer;

    [data-icon] {
        margin-right: .4rem;
    }
}
