html{
    font-size: 62.5%;
}

body{
    font-family: $urbanist;
    font-size: 1.4rem;
    color: $neutral-700;
}

a,
button{
    color: $neutral-700;
}