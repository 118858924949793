@import '../sass/reset.scss';
@import '../sass/normalize.scss';

@import './variables.scss';

@import './type.scss';

@import 'atoms/button.scss';

body{
    margin: 0 auto;
    padding: 1.6rem;
    max-width: 70rem;
    background: $neutral-50;
}
