$neutral-50:  #F7F8F9;
$neutral-100: #E7EAEE;
$neutral-200: #D0D5DD;
$neutral-300: #B8C0CC;
$neutral-400: #A0ABBB;
$neutral-500: #64748B;
$neutral-600: #4B5768;
$neutral-700: #323A46;
$neutral-800: #191D23;
$neutral-900: #0D0F11;

$buy-me-a-coffee-yellow: #FFDD00;

$urbanist:    'Urbanist', sans-serif;
