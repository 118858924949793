@import '../src/sass/variables.scss';

.header{
    font-size: 3rem;
    font-weight: bold;
}

.cards{
    display: grid;
    margin: 2.4rem 0;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    row-gap: 1.6rem;
    column-gap: 1.6rem;

    & > li{
        perspective: 100rem;
    }
}

.feed-back{
    display: grid;
    margin: 0 0 2.4rem 0;
    padding: 1.6rem;
    font-size: 1.6rem;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    list-style: none;
    text-align: center;
    border: .1rem solid $neutral-200;
    border-radius: .8rem;
    background: $neutral-100;

    span{
        display: block;
        margin-bottom: .5rem;
        font-size: 2.4rem;
        font-weight: bold;
    }
}

.install-banner{
    margin-top: 2rem;
    padding: 2rem;
    font-size: 1.8rem;
    line-height: 1;
    background: $neutral-500;
    color: $neutral-50;
    border-radius: .8rem;
    text-align: center;
    vertical-align: middle;

    p{
        margin-bottom: 1.5rem;
    }
}

.support-banner{
    margin-top: 2rem;
    padding: 2rem;
    font-size: 1.6rem;
    line-height: 1.2;
    background: $buy-me-a-coffee-yellow;
    color: $neutral-900;
    border-radius: .8rem;
    vertical-align: middle;

    h2{
        margin-bottom: 1rem;
        font-size: 2.4rem;
        font-weight: bold;
    }

    p{
        margin-bottom: 1.5rem;
    }
}

.modes-container{
    margin: 2rem 0;
}

.modes-list{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    margin-top: .5rem;
    column-gap: 1rem;
    row-gap: 1rem;

    li,
    button{
        display: block;
        width: 100%;
    }
}

.hidden{
    display: none;
}

.fireworks-container{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}

.fireworks-inner-container{
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.final-panel{
    position: relative;
    z-index: 2;
    display: block;
    width: 30rem;
    max-width: 100%;
    padding: 3rem;
    font-size: 1.6rem;
    line-height: 2rem;
    border: .1rem solid $neutral-200;
    border-radius: .8rem;
    background: $neutral-100;

    h2{
        margin-bottom: 1rem;
        font-weight: bold;
        font-size: 2rem;
        line-height: 2.5rem;
    }

    button{
        margin-top: 2rem;
    }
}