@import '../../../sass/variables.scss';

@keyframes guessed {
    0% {
        transform: rotateY(180deg) scale(1);
    }
    50% {
        transform: rotateY(180deg) scale(1.2);
    }
    100% {
        transform: rotateY(180deg) scale(1);
    }
}

.card{
    position: relative;
    border: .2rem solid $neutral-600;
    border-radius: .8rem;
    background: $neutral-300;
    padding-bottom: calc(100% - .8rem);
    cursor: pointer;
    transition: transform 0.8s;
    transform-style: preserve-3d;

    &.is-guessed{
        cursor: default;
    }

    &.is-pressed{
        transform: rotateY(180deg);
    }

    &.is-guessed{
        //transform: rotateY(180deg) scale(1.1);
        animation: guessed .8s ease-in-out 1 alternate;
    }

    .back{
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        font-size: 4.4rem;
        font-weight: bold;
        color: $neutral-100;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transform: rotateY(180deg);
    }
}

.image{
    height: 100%;
    width: 100%;
    max-width: 100%;
    border-radius: .6rem;
}